/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Title from '../Title';
import OutlineInput from '../FormField/OutlineInput';
import useReduxHandler from '../../../hooks/useReduxHandler';

const LoginForm = ({ onSubmit }) => {
	const [rerender, setRerender] = useState(false);
	const { register, errors, handleSubmit, reset, watch, setValue, control } =
		useForm();
	const { isLoading } = useReduxHandler();
	const rowRef = useRef();
	const onSubmitHandler = (data) => {
		if (onSubmit) onSubmit(data);
		reset(data);
	};

	useEffect(() => {
		if (rowRef.current) {
			console.log('row ref', rowRef.current);
			rowRef.current.focus();
			rowRef.current.click();
			setRerender(true);
		}
	}, []);

	const title = 'Partner Portal';
	const subtitle =
		'We maintain an extensive library to support our potential and existing Partners. Access business, operations, security, API and other integration information whenever you need it';

	return (
		<div ref={rowRef}>
			<Row className="padding-vertical">
				<Col sm="12" md={{ size: 12 }} className="padding-login">
					<Title
						title={title}
						subtitle={subtitle}
						classNameTitle="mb-4 display-4"
						hasSubTitle
						headingSubTitle="p"
						className="text-center"
					/>
					<Row className="d-flex justify-content-center">
						<Form
							className="col-md-9 pb-3 px-5"
							onSubmit={handleSubmit(onSubmitHandler)}
						>
							<div className="mt-5 mb-5">
								<OutlineInput
									name="username"
									label="User Name"
									required
									register={register}
									errors={errors}
									watch={watch}
									setValue={setValue}
									control={control}
									defaultValues={{ username: ' ' }}
									style={{ height: '50px' }}
								/>
							</div>
							<div className="mt-1 mb-5">
								<OutlineInput
									name="password"
									label="Password"
									required
									register={register}
									errors={errors}
									watch={watch}
									setValue={setValue}
									control={control}
									defaultValues={{ password: '' }}
									dataType="password"
									style={{ height: '50px' }}
									autocomplete="password"
									autoFocus
								/>
							</div>
							<Button
								color="primary"
								block
								type="submit"
								disabled={isLoading()}
							>
								Log In
							</Button>
						</Form>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

LoginForm.propTypes = {
	onSubmit: PropTypes.func,
};

LoginForm.defaultProps = {
	onSubmit: () => {},
};

export default LoginForm;
