/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Container } from 'reactstrap';
import SEO from '../../components/seo';
import Layout from '../../components/shared/Layout';

import RowManager from '../../components/containers/templates/shared/RowManager';
import SectionContainer from '../../components/containers/sections/SectionContainer';
import LoginForm from '../../components/shared/LoginForm';
import linkUtils from '../../components/utils/linkUtils';
import useReduxHandler from '../../hooks/useReduxHandler';
import { getBgColor } from '../../components/containers/templates/shared/ApiManager/util';
import contentParser from '../../components/utils/contentParser';
import '../styles/demo.scss';

export default function DemoPublicTemplate({ pageContext }) {
	const {
		pathname: path,
		slug,
		title,
		description,
		keywords,
		sections,
		styles,
		headerMenus,
		footerTopMenus,
		footerBottomMenus,
		heroVariant,
	} = pageContext;

	const {
		login,
		toggleOpenMeetingModal,
		toggleOpenContactModal,
		isLoggedIn,
		logout,
	} = useReduxHandler();
	const isLogged = isLoggedIn();
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		if (slug === 'login') {
			// setLoading(false);
			logout();
		}
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			let elements = document.getElementsByClassName('scroll-content');
			let screenSize = window.innerHeight;

			for (var i = 0; i < elements.length; i++) {
				var element = elements[i];

				if (element.getBoundingClientRect().top < screenSize) {
					element.classList.add('visible');
					handleRemoveClasses(element);
				} else {
					element.classList.remove('visible');
				}
			}
		};

		const handleRemoveClasses = (element) => {
			const effects = {
				fadeTop: 'fadeTop',
				fadeRight: 'fadeRight',
				fadeLeft: 'fadeLeft',
			};

			setTimeout(() => {
				element.classList.remove('visible');
				element.classList.remove(effects.fadeTop);
				element.classList.remove(effects.fadeRight);
				element.classList.remove(effects.fadeLeft);
			}, 3000);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleResize = () => {
		setIsMobile(window ? window.innerWidth <= 768 : false);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		setIsMobile(window ? window.innerWidth <= 768 : false);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const onClick = (type) => {
		if (type === 'meeting') {
			toggleOpenMeetingModal();
		} else if (type === 'contact') {
			toggleOpenContactModal();
		}
	};

	const viewMainSection = heroVariant !== null;

	const sectionContent = sections.filter(
		(section) => section.SectionType?.name !== 'hero'
	);

	if (slug === 'login') {
		const onSubmit = async (data) => {
			login(data.username, data.password);
		};

		return (
			<>
				<Layout
					header="dark"
					type="public"
					viewMainSection={false}
					classNameMain="bg-light"
					isPrivate={false}
					isLogged={isLogged}
					menu={headerMenus}
					footerTopMenu={footerTopMenus}
					footerBottomMenu={footerBottomMenus}
					path={path}
				>
					<SEO
						title={title}
						description={description}
						pathname={path}
						cssStyle={styles && contentParser.getOnlyContent(styles)}
					/>
					<div className="login-container">
						<SectionContainer
							className="padding-top-login"
							id={`login-form-${path}`}
						>
							<Container className="bg-white">
								<LoginForm onSubmit={(data) => onSubmit(data)} />
							</Container>
						</SectionContainer>
					</div>
					{sectionContent &&
						sectionContent.length > 0 &&
						sectionContent.map((section, index) => {
							if (section.name.includes('CSS')) {
								return null;
							}
							return (
								<SectionContainer
									key={`${section.id}`}
									id={
										section.sidebarTitle &&
										section.sidebarTitle.replace(/\s+/g, '_')
									}
									className={`public-section bg-white mt-5 ${slug}-section ${
										section.className || ''
									}`}
									slug={slug}
								>
									<RowManager
										key={section.id}
										row={section}
										slug={slug}
										changeVisibility={() => {}}
										isFirstSection={index === 0 ? true : false}
									/>
								</SectionContainer>
							);
						})}
				</Layout>
			</>
		);
	}

	function getSectionStyle(variant) {
		switch (variant?.name) {
			case 'superdarkblue':
				return ['main-section-blue', 'main-section-back-blue'];
			case 'blue-light':
				return ['main-section-blue-light', 'main-section-back-blue-light'];
			case 'gray':
				return ['main-section-gray', 'main-section-back-gray'];
			case 'green':
				return ['main-section-green', 'main-section-back-green'];
			case 'purple':
				return ['main-section-purple', 'main-section-back-purple'];
			case 'red':
				return ['main-section-red', 'main-section-back-red'];
			case 'gradient45lightbluelr':
				return [
					'main-section-Gradient45LightBlueLR',
					'main-section-back-Gradient45LightBlueLR',
				];
			case 'gradient45greenlr':
				return [
					'main-section-Gradient45GreenLR',
					'main-section-back-Gradient45GreenLR',
				];
			case 'gradient90graytb':
				return [
					'main-section-Gradient90GrayTB',
					'main-section-back-Gradient90GrayTB',
				];
			case 'gradient45darkbluelr':
				return [
					'main-section-Gradient45DarkBlueLR',
					'main-section-back-Gradient45DarkBlueLR',
				];
			case 'orangeligthingbolt':
				return [
					'main-section-orangeLigthingBolt',
					'main-section-back-orangeLigthingBolt',
				];
			case 'pinklight':
				return ['main-section-pinkLight', 'main-section-back-pinkLight'];
			default:
				return ['main-section-blue', 'main-section-back-blue'];
		}
	}

	function getHeroData() {
		if (!viewMainSection) return null;
		const heroSection = sections.filter(
			(section) => section.SectionType.name === 'hero'
		);
		const heroLeftSide =
			heroSection &&
			heroSection.length === 1 &&
			heroSection[0].sub_sections.filter(
				(item) => item.SubSectionType.name === 'heroleft'
			);
		const heroRightSide =
			heroSection &&
			heroSection.length === 1 &&
			heroSection[0].sub_sections.filter(
				(item) => item.SubSectionType.name === 'heroright'
			);

		const heroLeft =
			heroLeftSide && heroLeftSide.length === 1
				? {
						title: heroLeftSide && heroLeftSide[0] && heroLeftSide[0].title,
						text: ReactHtmlParser(heroLeftSide[0].content),
						image: heroLeftSide[0].imgUrl,
						video: heroLeftSide[0].videoUrl,
						videoFrame: heroLeftSide[0].VideoFrame?.name,
						className: heroLeftSide[0].className,
				  }
				: null;

		const heroRight =
			heroRightSide && heroRightSide.length === 1
				? {
						title: heroRightSide[0].title,
						text: ReactHtmlParser(heroRightSide[0].content),
						image: heroRightSide[0].imgUrl,
						video: heroRightSide[0].videoUrl,
						videoFrame: heroRightSide[0].VideoFrame?.name,
						className: heroRightSide[0].className,
				  }
				: null;

		const buttons =
			heroLeftSide &&
			heroLeftSide.length === 1 &&
			heroLeftSide[0].ctas &&
			heroLeftSide[0].ctas.map((cta) => {
				return {
					...cta,
					onClick: () => onClick(cta.CTAType?.name),
				};
			});

		const classStyle = getSectionStyle(heroVariant);

		return {
			heroLeft,
			heroRight,
			classStyle,
			buttons,
			heroSection: heroSection[0],
		};
	}

	function getheaderColor(variant) {
		switch (variant?.name) {
			case 'gray':
			case 'gradient90graytb':
			case null:
				return 'dark';
			default:
				return '';
		}
	}
	const color = getheaderColor(heroVariant);

	const pageMetaData = {
		title:
			'Subscription Management and Bill Negotiation Platform for Banks, Credit Unions and Fintechs',
		description:
			'ApexEdge is a white-label solution that allows banks and fintechs to grow revenue, increase customer satisfaction and retention, and users to stop subscription waste. ApexEdge simplifies users’ lives by empowering them to take control of their spend through an embedded bill management and financial insights platform. ApexEdge is subscription management made easy.',
		keywords:
			'apex edge, apexedge, bill negotiation, bill cancellation, billshark, bill shark, subscription management, how to negotiate bills, how to cancel subscriptions, banks, fintechs',
	};

	return (
		<>
			<Layout
				isPrivate={false}
				isLogged={isLogged}
				type="public"
				viewMainSection={viewMainSection}
				mainSection={getHeroData()}
				header={color}
				menu={headerMenus}
				footerTopMenu={footerTopMenus}
				footerBottomMenu={footerBottomMenus}
				path={path}
				slug={slug}
			>
				<SEO
					title={pageMetaData.title}
					description={pageMetaData.description}
					pathname={path}
					cssStyle={styles && contentParser.getOnlyContent(styles)}
					keywords={pageMetaData.keywords}
				/>

				{sectionContent &&
					sectionContent.length > 0 &&
					sectionContent.map((section, index) => {
						if (section.SectionType?.name === 'members') {
							return (
								<SectionContainer
									key={`${section.id}`}
									id={linkUtils.createLink(
										section.sidebarTitle,
										section.name,
										section.title,
										section.id
									)}
									className={`public-section ${slug}-section ${
										section.className || ''
									} ${index === 0 ? 'first-section' : ''}`}
									bgColor={getBgColor(section.bgColor)}
								>
									<RowManager
										key={section.id}
										row={section}
										isPrivate={false}
										slug={slug}
										changeVisibility={() => {}}
										isFirstSection={index === 0 ? true : false}
									/>
								</SectionContainer>
							);
						}
						const fade =
							index === 0 ? '' : index % 2 === 0 ? 'fadeRight' : 'fadeLeft';
						return (
							<SectionContainer
								key={`${section.name}`}
								id={linkUtils.createLink(
									section.sidebarTitle,
									section.name,
									section.title,
									section.id
								)}
								className={`public-section ${
									!isMobile && `scroll-content ${fade}`
								} ${slug}-section ${section.className || ''} ${
									index === 0 ? 'first-section' : ''
								}`}
								bgColor={getBgColor(section.bgColor)}
							>
								<Container>
									<RowManager
										key={section.id}
										row={section}
										isPrivate={false}
										slug={slug}
										changeVisibility={() => {}}
										isFirstSection={index === 0 ? true : false}
									/>
								</Container>
							</SectionContainer>
						);
					})}
			</Layout>
		</>
	);
}
